import React, { Component } from "react";
import PropTypes from "prop-types";

import SEWLogo from "!svg-react-loader!../../images/svg-icons/SEW_logo_black_wing.svg?name=SEWLogo";

// import SVGLineGraph from "../../images/svg-icons/linegraph.svg";
// import SVGmap from "../../images/svg-icons/map.svg";
// import SVGProfile from "../../images/svg-icons/profile-female.svg";
// import SVGNewspaper from "../../images/svg-icons/newspaper.svg";

// import MainLayout from "../../components/Main/Main";

import { Link } from "gatsby";

import { TimelineMax } from "gsap";

import SocialLinks from "../SocialLinks";

// import { FaPiedPiperAlt } from "react-icons/lib/fa";
// import { FaMapO } from "react-icons/lib/fa";
// import { FaCalendar } from "react-icons/lib/fa";
// import { FaArchive } from "react-icons/lib/fa";

// import { FaHome } from "react-icons/fa/";
// import { FaSearch } from "react-icons/fa/";
// import { FaEnvelope } from "react-icons/fa/";
// import { FaTag } from "react-icons/fa/";

class TitleAnimation extends Component {
  constructor() {
    super();

    this.logoTl = new TimelineMax({ paused: false });

    //this.tl = new TimelineLite({ paused: true });
    this.cardsTween = null;

    this.centerLogo = null;
    this.welcomeLinks = null;
    this.logoSVG = null;
    this.subTitle = null;
    this.feature = null;
    this.description = null;
    this.icons = [];

    this.cards = [];

    this.itemList = [
      { to: "/about/", label: "About Us" },
      { to: "/inspireus/", label: "Inspire Us" },
      { to: "/joinus/", label: "Join Us" },
      { to: "/society/", label: "Society" }
    ];
  }
  componentDidMount() {
    this.logoTl.delay(4);
    // this.logoTl
    //   .set(this.centerLogo, { autoAlpha: 0 })
    //   .set(this.subTitle, { autoAlpha: 0, y: 0 })
    //   .to(this.centerLogo, 1, { scale: 6, autoAlpha: 1, y: -150 })
    //   // .to(this.centerLogo, 1, { autoAlpha: 0 })
    //   .to(this.subTitle, 0.5, { autoAlpha: 1 })
    //   // .to(this.subTitle, 1, { autoAlpha: 1, y: 0 })
    //   .staggerTo(this.cards, 0.5, { autoAlpha: 1, y: -20 }, 0.2);
    // // this.tl.play();

    this.logoTl.delay(4);
    this.logoTl
      .set(this.centerLogo, { autoAlpha: 0, scale: 20, x: -300, y: 100, rotation: 30 })
      .set(this.subTitle, { autoAlpha: 0, y: 0 })
      .set(this.socialLinks, { autoAlpha: 0, y: 0 })
      // .to(this.centerLogo, 3, { autoAlpha: 1 })
      .to(this.centerLogo, 2, { scale: 6, autoAlpha: 1, y: -150, x: 0, rotation: 0 })
      .to(this.videoContainer, 2, { autoAlpha: 0.1 }, "-=2")
      // .to(this.centerLogo, 1, { autoAlpha: 0 })
      .to(this.subTitle, 0.5, { autoAlpha: 1 })
      // .to(this.subTitle, 1, { autoAlpha: 1, y: 0 })
      .staggerTo(this.cards, 0.5, { autoAlpha: 1, y: -20 }, 0.2)
      .to(this.socialLinks, 0.5, { autoAlpha: 1, y: 50 });
    // this.tl.play();

    this.logoTl.play();
  }
  render() {
    // this.logoTl
    //   .kill()
    //   .clear()
    //   .pause(0);

    const { backgrounds, theme } = this.props;

    return (
      <React.Fragment>
        <div className="container">
          {/* <MainLayout /> */}
          <video
            className="video-container video-container-overlay"
            autoPlay
            muted
            data-reactid=".0.1.0.0"
            ref={div => (this.videoContainer = div)}
            playsInline
          >
            <source
              type="video/mp4"
              data-reactid=".0.1.0.0.0"
              src="/uploads/wing_right_feathered_1.5loop.mp4"
            />
          </video>
          <div className="logo" ref={div => (this.centerLogo = div)}>
            <SEWLogo />
          </div>
          <div className="subtitle" ref={div => (this.subTitle = div)}>
            <h2>SOCIETY OF ERITREAN WOMEN</h2>
            <h4>I BELONG DEEPLY TO MYSELF. ~WARSAN SHIRE</h4>
          </div>
          {/* <div className="welcomeLinks" ref={div => (this.welcomeLinks = div)}>
            <Link to="/stories">
              <h2>Read Stories</h2>
            </Link>
            <Link to="/contact">
              <h2>Tell Your Story</h2>
            </Link>
          </div>  */}
          <div className="feature-icons">
            {this.itemList.map((element, index) => (
              <div className="card-element" key={index} ref={div => (this.cards[index] = div)}>
                <div className="">
                  <div className="feature-link">
                    <Link to={element.to}>
                      {/* <div className="feature-icon">
                        {
                          <element.icon
                            className="feature-icon-svg"
                            fill={theme.color.neutral.white}
                            strokeWidth="0"
                          />
                        }
                      </div> */}
                      <h2 className="feature-title">{element.label}</h2>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="social-links" ref={div => (this.socialLinks = div)}>
          <SocialLinks theme={theme} />
        </div>
        <style jsx>
          {`
            .container {
              width: 95%;
              text-align: center;

              .video-container {
                opacity: 0.2;
                width: 100%;
              }

              .video-container-overlay {
              }

              .feature-icons {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .card-element {
                  opacity: 0;
                  visibility: visible;
                  padding: ${theme.space.s};
                  border-radius: 0;
                  border: solid rgb(255, 255, 255, 1) 1px;

                  .feature-icon {
                    margin: auto;
                  }

                  .feature-title {
                    color: #fff;
                    font-weight: bold;
                  }
                  .feature-title:hover {
                    color: ${theme.color.neutral.gray.organge};
                    border-color: ${theme.color.special.attention};
                  }
                }

                .card-element:hover {
                  border: solid ${theme.color.neutral.gray.d} 1px;
                  background-color: color(white alpha(-90%));
                }
              }

              .logo {
                visibility: visible;
                fill: ${theme.color.neutral.white};
                margin: auto;
                opacity: 0;
              }

              .subtitle {
                color: ${theme.color.neutral.white};
                visibility: visible;
                font-weight: bold;
                border-left-style: solid;
                border-right-style: solid;
                border-width: 0px;
                border-left-color: #fff;
                border-right-color: #fff;
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;
                max-width: 100%;
                opacity: 0;

                h2 {
                  letter-spacing: ${`calc(${theme.heading.size.h2} * 0.25)`};
                  padding: 1rem 0px;
                }

                h4 {
                  letter-spacing: ${`calc(${theme.heading.size.h3} * 0.25)`};
                }
              }

              .welcomeLinks {
                visibility: visible;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                margin: ${theme.space.m};

                h2 {
                  color: ${theme.color.neutral.white};
                  font-weight: bold;
                  border-style: solid;
                  border-color: ${theme.color.neutral.gray.e};
                  border-radius: ${theme.size.radius.default};
                  padding: ${theme.space.m};
                  margin: ${theme.space.m};
                  white-space: nowrap;
                }

                h2:hover {
                  color: ${theme.color.brand.primaryActive};
                  border-color: ${theme.color.brand.primaryActive};
                }
              }
            }

            .social-links {
              opacity: 0;
            }

            @below mobile {
              .logo {
                width: ${theme.font.size.m};
                position: relative;
                top: 5em;
              }

              .video-container {
                position: relative;
                margin: auto;
              }
              .welcomeLinks {
                h2 {
                  font-size: ${`calc(${theme.heading.size.h3} * 0.3)`};
                  width: ${`calc(${theme.font.size.m} * 10)`};
                  border-width: ${`calc(${theme.space.xxs} * 0.5)`};
                }
              }
              .subtitle {
                font-size: ${`calc(${theme.heading.size.h3} * 0.5)`};
                font-weight: bold;
                padding: 2em 1em;

                &::before {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(-7em + 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }

                &::after {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(7em - 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }
              }

              .feature-icons {
                position: relative;
                top: calc(4em - 1px);
                left: -1px;

                .card-element {
                  .feature-link {
                    width: ${`calc(${theme.font.size.s} * 5)`};

                    .feature-icon {
                      width: ${`calc(${theme.font.size.s} * 2)`};
                    }

                    h2.feature-title {
                      font-size: ${`calc(${theme.heading.size.h3} * 0.5)`};
                    }
                  }
                }
              }
            }

            @between-from mobile tablet {
              .logo {
                width: ${theme.font.size.l};
                position: relative;
                top: 5em;
              }

              .video-container {
                position: relative;
                margin: auto;
              }

              .welcomeLinks {
                h2 {
                  font-size: ${`calc(${theme.heading.size.h3} * 0.6)`};
                  width: ${`calc(${theme.font.size.m} * 9)`};
                  border-width: ${`calc(${theme.space.xxs} * 0.5)`};
                }
              }

              .subtitle {
                font-size: ${`calc(${theme.heading.size.h3} * 0.5)`};
                font-weight: bold;
                padding: 3em 2rem;

                &::before {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(-7em + 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }

                &::after {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(7em - 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }
              }

              .feature-icons {
                position: relative;
                top: calc(4em - 1px);
                left: -1px;

                .card-element {
                  .feature-link {
                    width: ${`calc(${theme.font.size.s} * 5)`};

                    .feature-icon {
                      width: ${`calc(${theme.font.size.s} * 2)`};
                    }

                    h2.feature-title {
                      font-size: ${`calc(${theme.heading.size.h3} * 0.5)`};
                    }
                  }
                }
              }
            }

            @between-from tablet desktop {
              .logo {
                width: ${theme.font.size.l};
                position: relative;
                top: 5em;
              }

              .video-container {
                position: absolute;
                top: 30%;
                left: 0;
                width: 100%;
              }

              .welcomeLinks {
                h2 {
                  font-size: ${`calc(${theme.heading.size.h3} * 0.7)`};
                  width: ${`calc(${theme.font.size.s} * 10 )`};
                }
              }

              .subtitle {
                font-size: ${`calc(${theme.heading.size.h3} * 0.5)`};
                font-weight: bold;
                padding: 3em 2rem;

                &::before {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(-7em + 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }

                &::after {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(7em - 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }
              }

              .feature-icons {
                position: relative;
                top: calc(4em - 1px);
                left: -1px;
                margin-bottom: ${theme.font.size.m};

                .card-element {
                  .feature-link {
                    width: ${`calc(${theme.font.size.l} * 4)`};

                    .feature-icon {
                      width: ${`calc(${theme.font.size.xl} * 1)`};
                    }

                    .feature-title {
                      font-size: ${`calc(${theme.heading.size.h3} * 0.7)`};
                    }
                  }
                }
              }
            }

            @from-width desktop {
              .logo {
                width: ${theme.font.size.xxl};
                position: relative;
                top: 5em;
              }

              .video-container {
                opacity: 0.2;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              }
              .welcomeLinks {
                h2 {
                  font-size: ${`calc(${theme.heading.size.h3} * 1)`};
                  width: ${`calc(${theme.font.size.m} * 10)`};
                  border-width: ${theme.space.xxs};
                }
              }

              .subtitle {
                font-size: ${`calc(${theme.heading.size.h3} * 1)`};
                padding: 3em 2rem;

                &::before {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(-5em + 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }

                &::after {
                  content: "";
                  display: block;
                  position: relative;
                  top: ${`calc(5em - 1px)`};
                  left: calc(50% - 1px);
                  width: 1px;
                  height: calc(2.5rem + 1px);
                  background: #fff;
                }
              }

              .feature-icons {
                position: relative;
                top: calc(4em - 1px);
                left: -1px;

                .card-element {
                  .feature-link {
                    width: ${`calc(${theme.font.size.l} * 6)`};

                    .feature-icon {
                      width: ${`calc(${theme.font.size.l} * 3)`};
                      margin-bottom: ${`calc(${theme.font.size.m} * 1)`};
                    }

                    .feature-title {
                      font-size: ${`calc(${theme.heading.size.h3} * 0.9)`};
                      font-weight: normal;
                    }
                  }
                }
              }
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}

TitleAnimation.propTypes = {
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default TitleAnimation;
