import React from "react";
import PropTypes from "prop-types";

// import { FaArrowDown } from "react-icons/fa/";

// import FloatingPhotos from "../FloatingPhotos";
import TitleAnimation from "../TitleAnimation/TitleAnimation";

const Hero = props => {
  const { scrollToContent, backgrounds, theme } = props;

  return (
    <React.Fragment>
      <div className="hero">
        {/* <FloatingPhotos theme={theme} /> */}
        <TitleAnimation theme={theme} backgrounds={backgrounds} />

        {/* <button onClick={scrollToContent} aria-label="scroll">
          <FaArrowDown />
        </button> */}
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .hero {
          align-items: center;
          background-size: cover;
          background-color: ${theme.color.brand.primaryActive};
          color: ${theme.text.color.primary.inverse};
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          min-height: 100vh;
          height: 100px;
          padding: ${theme.space.inset.l};
          padding-top: ${theme.header.height.homepage};
        }

        h1 {
          text-align: center;
          font-size: ${theme.hero.h1.size};
          margin: ${theme.space.stack.s};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          text-remove-gap: both 0 "Philosopher";
          margin-top: 150px;

          :global(strong) {
            position: relative;

            &::after,
            &::before {
              content: "›";
              color: ${theme.text.color.attention};
              margin: 0 ${theme.space.xs} 0 0;
              text-shadow: 0 0 ${theme.space.s} ${theme.color.neutral.gray.k};
            }
            &::after {
              content: "‹";
              margin: 0 0 0 ${theme.space.xs};
            }
          }
        }

        h2 {
          color: ${theme.hero.h1.color};
          margin-bottom: 20px;
          text-align: center;
        }

        button {
          background: ${theme.background.color.brand};
          border: 0;
          border-radius: 50%;
          font-size: ${theme.font.size.m};
          padding: ${theme.space.s} ${theme.space.m};
          cursor: pointer;
          width: ${theme.space.xl};
          height: ${theme.space.xl};

          &:focus {
            outline-style: none;
            background: ${theme.color.brand.primaryActive};
          }

          :global(svg) {
            position: relative;
            top: 5px;
            fill: ${theme.color.neutral.white};
            stroke-width: 40;
            stroke: ${theme.color.neutral.white};
            animation-duration: ${theme.time.duration.long};
            animation-name: buttonIconMove;
            animation-iteration-count: infinite;
          }
        }

        .centerLogo {
          width: 300px;
          height: 100px;
          margin-bottom: 30px;
          border: 5px solid rgb(255, 255, 255, 0.25);
          overflow-y: hidden;

          .centerLogoViewed {
            animation-duration: ${theme.time.duration.superLong};
            animation-name: centerLogoMove;
            animation-iteration-count: infinite;

            animation-duration: ${theme.time.duration.long};
            animation-name: Pulsate;
            animation-iteration-count: infinite;

            animation: centerLogoMove 10s linear infinite, Pulsate 3.3s linear infinite;

            position: relative;
          }

          svg g .st0 {
            fill: #ffffff;
          }
        }

        @keyframes buttonIconMove {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        @keyframes Pulsate {
          from {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes centerLogoMove {
          0% {
            transform: translateY(-20px);
          }
          33% {
            transform: translateY(-100px);
          }
          67% {
            transform: translateY(-165px);
          }
          100% {
            transform: translateY(-20px);
          }
        }

        @below mobile {
          .hero {
            padding-top: 0px;
          }
        }

        @between-from mobile tablet {
          .hero {
            padding-top: ${theme.header.height.fixed};
          }
        }

        @between-from tablet desktop {
          .hero {
            padding-top: ${theme.header.height.homepage};
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }

          button {
            font-size: ${theme.font.size.l};
          }
        }

        @from-width desktop {
          .hero {
            padding-top: ${theme.header.height.homepage};
          }

          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
          }

          button {
            font-size: ${theme.font.size.xl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
