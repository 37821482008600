import PropTypes from "prop-types";
import React from "react";

import TwitterSVG from "!svg-react-loader!../../images/svg-icons/twitter.svg?name=TwitterSVG";

const SocialLinks = props => {
  const { theme } = props;

  return (
    <React.Fragment>
      <div className="social-links">
        <ul className="icons">
          <li>
            <a href="https://twitter.com/SEWSocialScene">
              <TwitterSVG></TwitterSVG>
            </a>
          </li>
          {/* <li>
            <a href="#" className="icon fa-facebook">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href="#" className="icon fa-instagram">
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a href="#" className="icon fa-github">
              <span className="label">GitHub</span>
            </a>
          </li> */}
        </ul>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .social-links {
          z-index: 2000;
          .icons {
            list-style: none;
            display: flex;

            li {
              background-color: white;
              border-radius: 50%;
              opacity: 0.5;

              :hover {
                opacity: 0.7;
              }

              :global(svg) {
                fill: #00aced;
              }
            }
          }
        }

        @below mobile {
          li {
            width: ${`calc(${theme.font.size.xxl} * .6)`};
            padding: ${`calc(${theme.space.xxs} * .2)`};
          }
        }

        @between-from mobile tablet {
          li {
            width: ${`calc(${theme.font.size.xxl} * 1)`};
          }
        }

        @between-from tablet desktop {
          li {
            width: ${`calc(${theme.font.size.xxl} * .8)`};
            padding: ${`calc(${theme.space.xxs} * .8)`};
          }
        }

        @from-width desktop {
          li {
            width: ${`calc(${theme.font.size.xxl} * 1)`};
            padding: ${`calc(${theme.space.xxs} * 1)`};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

SocialLinks.propTypes = {
  theme: PropTypes.object.isRequired
};

export default SocialLinks;
