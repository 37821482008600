import { FaArrowRight } from "react-icons/fa/";
import Img from "gatsby-image";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";

import { TimelineMax } from "gsap";

class SiteHighlight extends React.Component {
  constructor() {
    super();

    this.photoTl = new TimelineMax({ paused: false });

    this.siteHighlightContent = null;
    this.siteHighlightImg = null;
    this.siteHighlightExcerpt = null;
  }

  componentDidMount() {
    this.animateHighlightImage();
    //this.siteHighlightContent.addEventListener("wheel", this.animateHighlightImage);
  }

  animateHighlightImage() {
    // console.log("scrolled");
    this.photoTl
      .set(this.siteHighlightImg, { autoAlpha: 0, x: 30 })
      .set(this.siteHighlightExcerpt, { autoAlpha: 0, x: -30 })
      .to([this.siteHighlightExcerpt, this.siteHighlightImg], 1.5, { autoAlpha: 1, x: 0 });
  }

  visibilitySensorChange = val => {
    console.log(`visibilitySensorChange ` + val);
    if (val) this.animateHighlightImage();
  };

  render() {
    const {
      theme,
      post: {
        excerpt,
        fields: { slug, prefix },
        frontmatter: {
          title,
          category,
          author,
          cover: {
            children: [{ fluid }]
          }
        }
      },
      index
    } = this.props;

    return (
      <React.Fragment>
        <li ref={li => (this.siteHighlightContent = li)}>
          <div to="" key={slug} className="link" name={slug.split("/").join("")}>
            <section className="container">
              <div className={index % 2 == 0 ? "wrapper even" : "wrapper odd"}>
                <div className="excerpt" ref={div => (this.siteHighlightExcerpt = div)}>
                  <h2>{this.props.post.frontmatter.title}</h2>
                  <p>{this.props.post.excerpt}</p>
                </div>
                <div className="site-highlight-image" ref={div => (this.siteHighlightImg = div)}>
                  <Img fluid={fluid} />
                </div>
              </div>
            </section>
          </div>
        </li>

        <VisibilitySensor
          onChange={this.visibilitySensorChange}
          scrollCheck={true}
          delayedCall={true}
          partialVisibility={true}
          active={true}
          intervalCheck={true}
        >
          <div className="sensor" />
        </VisibilitySensor>

        {/* --- STYLES --- */}
        <style jsx>{`
          .container {
            background-color: ${theme.color.neutral.gray.j};
            margin-bottom: ${theme.space.m};
            height: ${`calc(${theme.heading.size.h2} * 20)`};
            color: white;

            .wrapper {
              display: flex;
              align-items: center;
              justify-content: left;
              flex-wrap: wrap;
              margin: ${theme.space.xxs};
              width: 100%;
              height: 100%;

              &.odd {
                flex-direction: row-reverse;
              }
            }
            .excerpt {
              text-align: left;

              h2 {
                font-weight: bold;
              }

              p {
                font-weight: normal;
              }
            }

            .site-highlight-image {
              opacity: 0;
            }
          }

          @below mobile {
            h2 {
              font-size: ${`calc(${theme.heading.size.h2} * 0.6)`};
              margin: ${theme.space.m};
              margin-bottom: ${theme.space.stack.m};
            }

            p {
              font-size: ${`calc(${theme.heading.size.h2} * 0.3)`};
              margin: ${theme.space.m};
            }

            .excerpt {
              width: 100%;
            }

            .site-highlight-image {
              width: 100%;
            }
          }

          @between-from mobile tablet {
            h2 {
              font-size: ${`calc(${theme.heading.size.h2} * 1)`};
              margin-bottom: ${theme.space.stack.m};
            }
            p {
              font-size: ${`calc(${theme.heading.size.h2} * 0.5)`};
              margin: ${theme.space.m};
            }
            .excerpt {
              width: 100%;
            }

            .site-highlight-image {
              width: 100%;
            }
          }

          @between-from tablet desktop {
            h2 {
              font-size: ${`calc(${theme.heading.size.h2} * 1.2)`};
              margin-bottom: ${theme.space.stack.m};
            }
            p {
              font-size: ${`calc(${theme.heading.size.h2} * 0.6)`};
              margin: ${theme.space.m};
            }
            .excerpt {
              width: 48%;
            }

            .site-highlight-image {
              width: 48%;
            }
          }

          @from-width desktop {
            h2 {
              font-size: ${`calc(${theme.heading.size.h2} * 1.5)`};
              margin: ${theme.space.l};
              margin-bottom: ${theme.space.stack.s};
            }
            p {
              font-size: ${`calc(${theme.heading.size.h2} * 0.75)`};
              margin-left: ${theme.space.xl};
              margin-right: ${theme.space.xl};
            }
            .excerpt {
              width: 48%;
              height: 100%;
            }

            .site-highlight-image {
              width: 48%;
            }
          }
        `}</style>
      </React.Fragment>
    );
  }
}

SiteHighlight.propTypes = {
  post: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default SiteHighlight;
